<template>
  <div class="flex items-center justify-between">
    <nav
      class="nav nav-common"
      role="tablist"
      aria-label="Tabs"
      aria-orientation="horizontal"
      :class="$attrs.class"
    >
      <button
        type="button"
        class="nav-item"
        role="tab"
        v-for="(tab_nav, tab_index) in tabList"
        :key="tab_nav.id"
        :id="`${tab_nav.id}-tab`"
        :aria-selected="tab_index === 0"
        :disabled="!tab_nav.enable"
        :data-hs-tab="`#${tab_nav.id}-content`"
        :aria-controls="`${tab_nav.id}-content`"
        :class="{ active: tab_index === 0 }"
      >
        <span class="nav-link">
          {{ $t(tab_nav.i18n_key) }}
        </span>
      </button>
    </nav>
    <slot name="sub-content"></slot>
  </div>
  <slot></slot>
</template>
<script setup>
import { onMounted } from "vue";

const props = defineProps({
  tabList: Array,
});

onMounted(() => {});
</script>
