<template>
  <div class="flex items-center w-full gap-2">
    <multiselect
      v-model="form.condition.target"
      :options="targetOptions"
      :show-labels="false"
      :allow-empty="false"
      :taggable="true"
      @tag="addTarget"
      :max-height="120"
      :placeholder="$t('helpers.select')"
      class="!min-w-[auto] max-w-[max-content]"
    >
      <template #caret>
        <IconSvg
          icon="arrow_down"
          class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
        ></IconSvg>
      </template>
    </multiselect>

    <multiselect
      v-model="form.condition.operator"
      :options="props.operator_options"
      :show-labels="false"
      :searchable="false"
      :allow-empty="false"
      :max-height="120"
      class="!min-w-[auto] max-w-[max-content]"
    >
      <template #singleLabel="props">
        {{ $t(`supplier/special_discount.conditions.${props.option}`) }}
      </template>
      <template #option="props">
        {{ $t(`supplier/special_discount.conditions.${props.option}`) }}
      </template>
      <template #caret>
        <IconSvg
          icon="arrow_down"
          class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
        ></IconSvg>
      </template>
    </multiselect>

    <input
      v-model="form.condition.content"
      :placeholder="
        $t('supplier/special_discount.conditions.content_placeholder')
      "
      type="text"
      class="input"
      :id="`condition_${props.index}_content`"
    />

    <button @click="removeCondition(index)" class="flex items-center">
      <IconSvg icon="delete" class="size-6 fill-warning"></IconSvg>
    </button>
  </div>
</template>
<script setup>
import { ref, watch, onMounted } from "vue";
import { useForm } from "@inertiajs/vue3";
const props = defineProps({
  condition: Object,
  index: Number,
  target_options: Array,
  operator_options: Array,
});

const form = useForm({
  condition: props.condition,
});

const emit = defineEmits(["removeCondition", "addTarget", "submit"]);

const removeCondition = () => {
  emit("removeCondition", props.index);
};

const targetOptions = ref(props.target_options);

const addTarget = (target) => {
  form.condition.target = target;
  emit("addTarget", target);
};

onMounted(() => {
  watch(form.condition, () => {
    emit("submit", form.condition, props.index);
  });
});
</script>
