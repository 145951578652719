<template>
  <div
    id="permission-content"
    role="tabpanel"
    aria-labelledby="permission-tab"
    class="hidden"
  >

  </div>
</template>
