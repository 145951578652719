const validate = (cloud_account) => {
  return (
    !!cloud_account.account_id &&
    !!cloud_account.start_at &&
    !!cloud_account.status &&
    !!cloud_account.supplier_pricing_item_ids
  );
};

export { validate };
