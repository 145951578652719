<template>
  <div class="space-y-5">
    <dl class="grid grid-cols-10 gap-5 my-5">
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_status">{{
          $t("activerecord.attributes.organization.status")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-1 gap-5">
        <multiselect
          v-model="form.organization.status"
          :options="props.options_for_select.statuses"
          :show-labels="false"
          :allow-empty="false"
          :max-height="120"
          class="!min-w-full"
          id="organization_status"
          :placeholder="$t('helpers.select')"
        >
          <template #singleLabel="props">
            {{ $t(`organization.status.${props.option}`) }}
          </template>
          <template #option="props">
            {{ $t(`organization.status.${props.option}`) }}
          </template>
          <template #caret>
            <IconSvg
              icon="arrow_down"
              class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
            ></IconSvg>
          </template>
        </multiselect>
      </dd>
    </dl>
    <hr class="border-gray-40" />
    <dl class="grid grid-cols-10 gap-5 my-5">
      <dt class="flex items-center col-span-10">
        <h4 class="text-lg font-bold">
          {{ $t("organization.form.operation_system_sync_data") }}
        </h4>
      </dt>
    </dl>
    <dl
      class="grid grid-cols-10 gap-5 px-5 py-3 my-5 -mx-5 border-y border-gray-20 bg-gray-5"
    >
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_subsidiary">{{
          $t("activerecord.attributes.organization.subsidiary")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-9 gap-5">
        <label
          v-for="subsidiary in props.options_for_select.subsidiaries"
          :for="`subsidiary_${subsidiary}`"
          class="custom-radio"
        >
          <input
            type="radio"
            :id="`subsidiary_${subsidiary}`"
            v-model="form.organization.subsidiary"
            :value="subsidiary"
          />
          {{ $t(`organization.subsidiary.${subsidiary}`) }}
          <span class="checkmark"></span>
        </label>
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_name">{{
          $t("activerecord.attributes.organization.name")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-3 gap-5">
        <input
          type="text"
          id="organization_name"
          v-model="form.organization.name"
          class="input"
        />
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_operation_no">{{
          $t("activerecord.attributes.organization.operation_no")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-3 gap-5">
        <input
          type="text"
          id="organization_operation_no"
          v-model="form.organization.operation_no"
          class="input"
        />
      </dd>
      <dd class="col-span-2"></dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_customer_name">{{
          $t("activerecord.attributes.organization.customer_name")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-3 gap-5">
        <input
          type="text"
          id="organization_customer_name"
          v-model="form.organization.customer_name"
          class="input"
        />
      </dd>
      <dt class="flex items-center col-span-1">
        <label
          class="ml-3 required"
          for="organization_tax_registration_number"
          >{{
            $t("activerecord.attributes.organization.tax_registration_number")
          }}</label
        >
      </dt>
      <dd class="flex items-center col-span-3 gap-5">
        <input
          type="text"
          id="organization_tax_registration_number"
          v-model="form.organization.tax_registration_number"
          class="input"
        />
      </dd>
      <dd class="col-span-2"></dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_brief_name">{{
          $t("activerecord.attributes.organization.brief_name")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-3 gap-5">
        <input
          type="text"
          id="organization_brief_name"
          v-model="form.organization.brief_name"
          class="input"
        />
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_category">{{
          $t("activerecord.attributes.organization.category")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-3 gap-5">
        <multiselect
          v-model="form.organization.category"
          :options="props.options_for_select.categories"
          :show-labels="false"
          :searchable="false"
          :allow-empty="false"
          :max-height="120"
          class="!min-w-full"
          id="organization_category"
        >
          <template #singleLabel="props">
            {{ $t(`organization.category.${props.option}`) }}
          </template>
          <template #option="props">
            {{ $t(`organization.category.${props.option}`) }}
          </template>
          <template #caret>
            <IconSvg
              icon="arrow_down"
              class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
            ></IconSvg>
          </template>
        </multiselect>
      </dd>
      <dd class="col-span-2"></dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_industry">{{
          $t("activerecord.attributes.organization.industry")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-3 gap-5">
        <multiselect
          v-model="form.organization.industry"
          :options="props.options_for_select.industries"
          :show-labels="false"
          :searchable="false"
          :allow-empty="false"
          :max-height="120"
          class="!min-w-full"
          id="organization_industry"
        >
          <template #singleLabel="props">
            {{ $t(`organization.industry.${props.option}`) }}
          </template>
          <template #option="props">
            {{ $t(`organization.industry.${props.option}`) }}
          </template>
          <template #caret>
            <IconSvg
              icon="arrow_down"
              class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
            ></IconSvg>
          </template>
        </multiselect>
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_country">{{
          $t("activerecord.attributes.organization.country")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-3 gap-5">
        <multiselect
          v-model="selectedCountry"
          :options="props.options_for_select.countries"
          :show-labels="false"
          :allow-empty="false"
          :max-height="120"
          class="!min-w-full"
          id="organization_country"
          :placeholder="$t('helpers.select')"
          track-by="country_code"
          @select="form.organization.country = $event.country_code"
        >
          <template #singleLabel="props">
            {{ props.option.country_name }}
          </template>
          <template #option="props">
            {{ props.option.country_name }}
          </template>
          <template #caret>
            <IconSvg
              icon="arrow_down"
              class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
            ></IconSvg>
          </template>
        </multiselect>
      </dd>
      <dd class="col-span-2"></dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_postal_code">{{
          $t("activerecord.attributes.organization.postal_code")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-1">
        <input
          type="text"
          id="organization_postal_code"
          v-model="form.organization.postal_code"
          class="input !min-w-[auto]"
        />
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_address">{{
          $t("activerecord.attributes.organization.address")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-5 gap-5">
        <input
          type="text"
          id="organization_address"
          v-model="form.organization.address"
          class="input"
        />
      </dd>
      <dd class="col-span-2"></dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_payment_term">{{
          $t("activerecord.attributes.organization.payment_term")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-1">
        <multiselect
          v-model="form.organization.payment_term"
          :options="props.options_for_select.payment_terms"
          :show-labels="false"
          :allow-empty="false"
          :max-height="120"
          class="!min-w-full"
          id="organization_payment_term"
          :placeholder="$t('helpers.select')"
        >
          <template #singleLabel="props">
            {{ $t(`organization.payment_term.${props.option}`) }}
          </template>
          <template #option="props">
            {{ $t(`organization.payment_term.${props.option}`) }}
          </template>
          <template #caret>
            <IconSvg
              icon="arrow_down"
              class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
            ></IconSvg>
          </template>
        </multiselect>
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_owner_unit">{{
          $t("activerecord.attributes.organization.owner_unit")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-5 gap-5">
        <label
          v-for="owner_unit in props.options_for_select.owner_units"
          :for="`owner_unit_${owner_unit}`"
          class="custom-radio"
        >
          <input
            type="radio"
            :id="`owner_unit_${owner_unit}`"
            v-model="form.organization.owner_unit"
            :value="owner_unit"
          />
          {{ $t(`organization.owner_unit.${owner_unit}`) }}
          <span class="checkmark"></span>
        </label>
      </dd>
    </dl>
    <hr class="border-gray-40" />

    <dl class="grid grid-cols-9 gap-5 my-5">
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_owner">{{
          $t("activerecord.attributes.organization.owner")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5">
        <multiselect
          v-model="selectedOwner"
          :options="props.options_for_select.owners"
          :show-labels="false"
          :allow-empty="false"
          :max-height="120"
          class="!min-w-full"
          id="organization_owner"
          :placeholder="$t('helpers.select')"
          @select="form.organization.owner_id = $event.id"
        >
          <template #singleLabel="props">
            {{ `${props.option.email} (${props.option.name})` }}
          </template>
          <template #option="props">
            {{ `${props.option.email} (${props.option.name})` }}
          </template>
          <template #caret>
            <IconSvg
              icon="arrow_down"
              class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
            ></IconSvg>
          </template>
        </multiselect>
      </dd>
      <dd class="col-span-6"></dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_primary_contact_email">{{
          $t("activerecord.attributes.organization.primary_contact_email")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-2 gap-2 w-max">
        <input
          class="flex-1 input"
          id="organization_primary_contact_email"
          type="email"
          v-model="form.organization.primary_contact.email"
          :disabled="primaryContactEmailChecked"
        />
        <button
          @click="changePrimaryContactEmail"
          type="button"
          class="btn btn-primary btn-xs"
          v-if="primaryContactEmailChecked"
        >
          {{ $t("helpers.change") }}
        </button>
        <button
          v-else
          @click="checkPrimaryContactEmail"
          type="button"
          class="btn btn-primary btn-xs"
          :disabled="
            !form.organization.primary_contact.email ||
            !emailValidate(form.organization.primary_contact.email)
          "
        >
          {{ $t("helpers.check") }}
        </button>
      </dd>
      <dd class="col-span-1"></dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_billing_contact_email">{{
          $t("activerecord.attributes.organization.billing_contact_email")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-2 gap-2 w-max">
        <input
          class="flex-1 input"
          id="organization_billing_contact_email"
          type="email"
          v-model="form.organization.billing_contact.email"
          :disabled="billingContactEmailChecked"
        />
        <button
          @click="changeBillingContactEmail"
          type="button"
          class="btn btn-primary btn-xs"
          v-if="billingContactEmailChecked"
        >
          {{ $t("helpers.change") }}
        </button>
        <button
          v-else
          @click="checkBillingContactEmail"
          type="button"
          class="btn btn-primary btn-xs"
          :disabled="
            !form.organization.billing_contact.email ||
            !emailValidate(form.organization.billing_contact.email)
          "
        >
          {{ $t("helpers.check") }}
        </button>
      </dd>
      <dd class="col-span-2"></dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_primary_contact_name">{{
          $t("activerecord.attributes.organization.primary_contact_name")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <input
          class="input"
          id="organization_primary_contact_name"
          type="text"
          v-model="form.organization.primary_contact.name"
          :disabled="!freshPrimaryContact"
        />
      </dd>
      <dd class="col-span-1"></dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_billing_contact_name">{{
          $t("activerecord.attributes.organization.billing_contact_name")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <input
          class="input"
          id="organization_billing_contact_name"
          type="text"
          v-model="form.organization.billing_contact.name"
          :disabled="!freshBillingContact"
        />
      </dd>
      <dd class="col-span-2"></dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="organization_primary_contact_cellphone">{{
          $t("activerecord.attributes.organization.primary_contact_cellphone")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-2 gap-2 w-max">
        <input
          class="input"
          id="organization_primary_contact_cellphone"
          type="text"
          v-model="form.organization.primary_contact.cellphone"
          :disabled="!freshPrimaryContact"
        />
      </dd>
      <dd class="col-span-1"></dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="organization_billing_contact_cellphone">{{
          $t("activerecord.attributes.organization.billing_contact_cellphone")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-2 gap-2 w-max">
        <input
          class="input"
          id="organization_billing_contact_cellphone"
          type="text"
          v-model="form.organization.billing_contact.cellphone"
          :disabled="!freshBillingContact"
        />
      </dd>
      <dd class="col-span-2"></dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_bill_sending">{{
          $t("activerecord.attributes.organization.bill_sending")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-1 gap-5">
        <label
          v-for="bill_sending in ['true', 'false']"
          :for="`organization_bill_sending_${bill_sending}`"
          class="custom-radio"
        >
          <input
            type="radio"
            :id="`organization_bill_sending_${bill_sending}`"
            v-model="form.organization.bill_sending"
            :value="bill_sending"
          />
          {{ $t(`helpers.${bill_sending}`) }}
          <span class="checkmark"></span>
        </label>
      </dd>
      <dd class="col-span-1"></dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_cloud_detail_sending">{{
          $t("activerecord.attributes.organization.cloud_detail_sending")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-1 gap-5 w-max">
        <label
          v-for="cloud_detail_sending in ['true', 'false']"
          :for="`organization_cloud_detail_sending_${cloud_detail_sending}`"
          class="custom-radio"
        >
          <input
            type="radio"
            :id="`organization_cloud_detail_sending_${cloud_detail_sending}`"
            v-model="form.organization.cloud_detail_sending"
            :value="cloud_detail_sending"
          />
          {{ $t(`helpers.${cloud_detail_sending}`) }}
          <span class="checkmark"></span>
        </label>
      </dd>
      <dd class="col-span-4"></dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="organization_bill_password">{{
          $t("activerecord.attributes.organization.bill_password")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-8 gap-2 w-max">
        <input
          type="text"
          class="flex-1 input"
          v-if="sameAsTaxRegistrationNumber"
          disabled
        />
        <input
          class="flex-1 input"
          id="organization_bill_password"
          type="password"
          autocomplete="off"
          v-model="form.organization.bill_password"
          v-else
        />

        <label
          for="organization_same_as_tax_registration_number"
          class="custom-checkbox"
        >
          <input
            v-model="sameAsTaxRegistrationNumber"
            type="checkbox"
            id="organization_same_as_tax_registration_number"
          />
          {{
            $t(
              "organization.form.bill_password.same_as_tax_registration_number"
            )
          }}
          <span class="checkmark"></span>
        </label>
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="organization_bill_format">{{
          $t("activerecord.attributes.organization.bill_format")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <multiselect
          v-model="form.organization.bill_format"
          :options="props.options_for_select.bill_formats"
          :show-labels="false"
          :allow-empty="false"
          :max-height="120"
          id="organization_bill_format"
          :placeholder="$t('helpers.select')"
        >
          <!-- <template #singleLabel="props">
            {{ $t(`organization.bill_format.${props.option}`) }}
          </template>
          <template #option="props">
            {{ $t(`organization.bill_format.${props.option}`) }}
          </template> -->
          <template #caret>
            <IconSvg
              icon="arrow_down"
              class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
            ></IconSvg>
          </template>
        </multiselect>
      </dd>
      <dd class="flex items-center col-span-3 gap-1 w-max">
        <label class="ml-3 required" for="organization_bill_consolidation">{{
          $t("activerecord.attributes.organization.bill_consolidation")
        }}</label>
        <IconSvg icon="info" class="w-4 h-4 fill-gray-80"></IconSvg>

        <multiselect
          v-model="form.organization.bill_consolidation"
          :options="props.options_for_select.bill_consolidations"
          :show-labels="false"
          :allow-empty="false"
          :max-height="120"
          id="organization_bill_consolidation"
          :placeholder="$t('helpers.select')"
          class="flex-1"
        >
          <template #singleLabel="props">
            {{ $t(`organization.bill_consolidation.${props.option}`) }}
          </template>
          <template #option="props">
            {{ $t(`organization.bill_consolidation.${props.option}`) }}
          </template>
          <template #caret>
            <IconSvg
              icon="arrow_down"
              class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
            ></IconSvg>
          </template>
        </multiselect>
      </dd>
      <dd class="col-span-3"></dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="organization_comment">{{
          $t("activerecord.attributes.organization.comment")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-6">
        <input
          class="input"
          id="organization_comment"
          type="text"
          v-model="form.organization.comment"
        />
      </dd>
    </dl>
  </div>

</template>
<script setup>
import { ref, watch, onMounted } from "vue";
import { useForm } from "@inertiajs/vue3";

const props = defineProps({
  organization: Object,
  options_for_select: Object,
  authenticity_token: String,
});

const form = useForm({
  organization: props.organization,
});

const selectedCountry = ref(
  props.options_for_select.countries.find(
    (country) => country.country_code === props.organization.country
  )
);

const selectedOwner = ref(
  props.options_for_select.owners.find(
    (owner) => owner.id === props.organization.owner_id
  )
);

const primaryContactEmailChecked = ref(false);
const freshPrimaryContact = ref(false);
const changePrimaryContactEmail = () => {
  primaryContactEmailChecked.value = false;
  freshPrimaryContact.value = false;
};
const checkPrimaryContactEmail = () => {
  fetch("/users/check", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": props.authenticity_token,
    },
    body: JSON.stringify({
      user: { email: form.organization.primary_contact.email },
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      primaryContactEmailChecked.value = true;
      if (data.user.id) {
        form.organization.primary_contact.name = data.user.name;
        form.organization.primary_contact.cellphone = data.user.cellphone;
      } else {
        freshPrimaryContact.value = true;
      }
    });
};

const billingContactEmailChecked = ref(false);
const freshBillingContact = ref(false);
const changeBillingContactEmail = () => {
  billingContactEmailChecked.value = false;
  freshBillingContact.value = false;
};
const checkBillingContactEmail = () => {
  fetch("/users/check", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": props.authenticity_token,
    },
    body: JSON.stringify({
      user: { email: form.organization.billing_contact.email },
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      billingContactEmailChecked.value = true;
      if (data.user.id) {
        form.organization.billing_contact.name = data.user.name;
        form.organization.billing_contact.cellphone = data.user.cellphone;
      } else {
        freshBillingContact.value = true;
      }
    });
};

const sameAsTaxRegistrationNumber = ref(
  props.organization.tax_registration_number &&
    props.organization.bill_password ===
      props.organization.tax_registration_number
);

const emit = defineEmits(["submit"]);

onMounted(() => {
  watch(sameAsTaxRegistrationNumber, () => {
    if (sameAsTaxRegistrationNumber.value) {
      form.organization.bill_password =
        form.organization.tax_registration_number;
    } else {
      form.organization.bill_password = null;
    }
  });
  watch(form.organization, () => {
    emit("submit", form.organization);
  });
});
</script>
