<template>
  <!-- Modal toggle -->
  <button
    type="button"
    aria-haspopup="dialog"
    aria-expanded="false"
    :aria-controls="modalId"
    :data-hs-overlay="`#${modalId}`"
    :class="$attrs.class"
    :disabled="disabled"
    @click.prevent.stop
  >
    <slot name="button">
      {{ buttonText || $t("helpers.delete") }}
    </slot>
  </button>

  <!-- Main modal -->
  <Teleport to="body">
    <div
      :id="modalId"
      tabindex="-1"
      role="dialog"
      data-hs-overlay-keyboard="false"
      class="hs-overlay hidden overflow-y-auto overflow-x-hidden [--overlay-backdrop:static] size-full fixed top-0 start-0 z-[80] pointer-events-none"
      :aria-labelledby="`${modalId}-title`"
    >
      <div
        class="h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] mx-auto w-full flex items-center"
        :class="{
          'max-w-4xl': modalSize === 'lg',
          'max-w-2xl': modalSize === 'md',
          'max-w-lg': modalSize === 'sm',
        }"
      >
        <!-- Modal content -->
        <div
          class="flex flex-col w-full max-h-full py-3 overflow-hidden bg-white rounded shadow pointer-events-auto"
        >
          <!-- Modal header -->
          <template v-if="hasModelHeaderSlot">
            <slot name="modelHeader"></slot>
          </template>
          <template v-else>
            <div
              v-if="props.modalTitle"
              class="flex items-center gap-5 px-4 py-3"
            >
              <h3
                :id="`${modalId}-title`"
                class="text-xl font-bold text-gray-80"
              >
                {{ props.modalTitle }}
              </h3>
              <slot name="sub-title"></slot>
            </div>
          </template>

          <!-- Modal body -->
          <div class="px-4 py-3 overflow-y-auto text-gray-80">
            <slot></slot>
          </div>

          <!-- Modal footer -->
          <template v-if="withConfirmText">
            <!-- confirm text footer -->
            <div class="px-4 py-3 border-t border-gray-200">
              <p class="mb-2">
                <span v-t="'helpers.modal.confirm_enter1'"></span>
                <span class="px-2 py-1 mx-1 badge">{{ modalConfirmText }}</span>
                <span v-t="'helpers.modal.confirm_enter2'"></span>
              </p>
              <input v-model="confirmText" type="text" class="mb-3 input" />

              <button
                @click="onSubmit"
                :disabled="confirmText !== modalConfirmText"
                :data-hs-overlay="`#${modalId}`"
                type="button"
                :class="modalSubmitClass"
                class="w-full btn"
              >
                {{ modalSubmitText || $t("helpers.confirm") }}
              </button>
            </div>
          </template>

          <template v-else>
            <!-- normal modal footer -->
            <div
              class="flex items-center justify-end gap-3 px-4 py-3"
              :class="{ 'w-1/2 ml-auto': modalSize === 'xl' }"
            >
              <button
                :data-hs-overlay="`#${modalId}`"
                type="button"
                class="w-1/3 btn btn-sm"
                :class="modalCancelClass || 'btn-primary btn-outline'"
              >
                {{ modalCancelText || $t("helpers.cancel") }}
              </button>
              <button
                @click="onSubmit"
                :data-hs-overlay="`#${modalId}`"
                type="button"
                class="w-1/3 btn btn-sm"
                :class="modalSubmitClass || 'btn-warning'"
                :disabled="preventSubmit"
              >
                {{ modalSubmitText || $t("helpers.confirm") }}
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { getCurrentInstance, ref, useSlots, computed } from "vue";

const instance = getCurrentInstance();
const modalId = `default-modal-${instance.uid}`;

const props = defineProps({
  buttonText: { type: String },
  modalTitle: { type: String },
  modalSubmitClass: { type: String },
  modalSubmitText: { type: String },
  modalCancelClass: { type: String },
  modalCancelText: { type: String },
  withConfirmText: { type: Boolean, default: false },
  modalConfirmText: { type: String },
  modalSize: { type: String, default: "sm" },
  disabled: { type: Boolean, default: false },
  preventSubmit: { type: Boolean, default: false },
});

const confirmText = ref("");

// // emit
const emit = defineEmits(["submit"]);
const onSubmit = () => {
  emit("submit");
};

const slots = useSlots();
const hasModelHeaderSlot = computed(() => {
  return !!slots.modelHeader;
});
</script>
