<template>
  <ConfirmModal
    :disabled="download_ids.length === 0"
    :modal-cancel-text="$t('helpers.back')"
    :modal-submit-text="$t('helpers.download', { text: '' })"
    modal-submit-class="btn btn-sm btn-primary"
    :modal-title="
      $t('helpers.download', {
        text: `${$t('nav.cloud_account_id')}${$t('helpers.list')}`,
      })
    "
    modal-size="xl"
    @submit="downloadCloudAccounts"
    class="btn btn-sm btn-primary"
  >
    <template #sub-title>
      <button
        class="btn btn-sm btn-outline btn-primary"
        type="button"
        @click="clearDownloadColumns"
      >
        <IconSvg icon="status_no" class="w-4 h-4 fill-primary-dark"></IconSvg>
        {{ $t("cloud_account.download_list.clear_all") }}
      </button>
    </template>
    <template #button>
      <IconSvg icon="download" class="w-4 h-4 fill-white"></IconSvg>
      {{
        $t("helpers.download", {
          text: `${$t("nav.cloud_account_id")}${$t("helpers.list")}`,
        })
      }}
    </template>

    <div class="grid grid-cols-6 gap-10">
      <div class="flex flex-col gap-2">
        <h5 class="text-sm font-bold text-gray-80">
          {{ $t("cloud_account.index.modal.info") }}
        </h5>

        <label
          :for="`download_info_${column}`"
          class="custom-checkbox"
          v-for="column in categoryColumns.info"
          :key="column"
        >
          <input
            type="checkbox"
            :id="`download_info_${column}`"
            v-model="download_columns"
            :value="column"
            :disabled="column === 'account_id'"
            class="download-column"
          />
          {{ $t(`cloud_account.index.modal.${column}`) }}
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="flex flex-col gap-5">
        <div class="flex flex-col gap-2">
          <h5 class="text-sm font-bold text-gray-80">
            {{ $t("cloud_account.index.modal.client_info") }}
          </h5>

          <label
            :for="`download_client_info_${column}`"
            class="custom-checkbox"
            v-for="column in categoryColumns.client_info"
            :key="column"
          >
            <input
              type="checkbox"
              :id="`download_client_info_${column}`"
              v-model="download_columns"
              :value="column"
              class="download-column"
            />
            {{ $t(`cloud_account.index.modal.${column}`) }}
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="flex flex-col gap-2">
          <h5 class="text-sm font-bold text-gray-80">
            {{ $t("cloud_account.index.modal.support_way") }}
          </h5>

          <label
            :for="`download_support_way_${column}`"
            class="custom-checkbox"
            v-for="column in categoryColumns.support_way"
            :key="column"
          >
            <input
              type="checkbox"
              :id="`download_support_way_${column}`"
              v-model="download_columns"
              :value="column"
              class="download-column"
            />
            {{ $t(`cloud_account.index.modal.${column}`) }}
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <h5 class="text-sm font-bold text-gray-80">
          {{ $t("cloud_account.index.modal.client_business") }}
        </h5>

        <label
          :for="`download_client_business_${column}`"
          class="custom-checkbox"
          v-for="column in categoryColumns.client_business"
          :key="column"
        >
          <input
            type="checkbox"
            :id="`download_client_business_${column}`"
            v-model="download_columns"
            :value="column"
            class="download-column"
          />
          {{ $t(`cloud_account.index.modal.${column}`) }}
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="flex flex-col gap-2">
        <h5 class="text-sm font-bold text-gray-80">
          {{
            $t("cloud_account.index.modal.client_cloud_account_pricing_items")
          }}
        </h5>

        <label
          :for="`download_client_cloud_account_pricing_items_${column}`"
          class="custom-checkbox"
          v-for="column in categoryColumns.client_cloud_account_pricing_items"
          :key="column"
        >
          <input
            type="checkbox"
            :id="`download_client_cloud_account_pricing_items_${column}`"
            v-model="download_columns"
            :value="column"
            class="download-column"
          />
          {{ $t(`cloud_account.index.modal.${column}`) }}
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="flex flex-col gap-2">
        <h5 class="text-sm font-bold text-gray-80">
          {{ $t("cloud_account.index.modal.dgc_cost") }}
        </h5>

        <label
          :for="`download_dgc_cost_${column}`"
          class="custom-checkbox"
          v-for="column in categoryColumns.dgc_cost"
          :key="column"
        >
          <input
            type="checkbox"
            :id="`download_dgc_cost_${column}`"
            v-model="download_columns"
            :value="column"
            class="download-column"
          />
          {{ $t(`cloud_account.index.modal.${column}`) }}
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="flex flex-col gap-2">
        <h5 class="text-sm font-bold text-gray-80">
          {{
            $t("cloud_account.index.modal.supplier_cloud_account_pricing_items")
          }}
        </h5>

        <label
          :for="`download_supplier_cloud_account_pricing_items_${column}`"
          class="custom-checkbox"
          v-for="column in categoryColumns.supplier_cloud_account_pricing_items"
          :key="column"
        >
          <input
            type="checkbox"
            :id="`download_supplier_cloud_account_pricing_items_${column}`"
            v-model="download_columns"
            :value="column"
            class="download-column"
          />
          {{ $t(`cloud_account.index.modal.${column}`) }}
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </ConfirmModal>
  <JsonCSV class="hidden" :data="csvData" ref="csvDownloadBtn" :name="`${$t('nav.cloud_account_id')}.csv`" />
</template>

<script setup>
import { ref, onMounted, computed, nextTick } from "vue";
import { router } from "@inertiajs/vue3"
import { categoryColumns } from "./category_columns";
import JsonCSV from 'vue-json-csv'

const props = defineProps({
  authenticity_token: String,
  download_ids: Array,
});

const download_ids = computed(() => {
  return props.download_ids;
});

const download_columns = ref([]);

const clearDownloadColumns = () => {
  download_columns.value = ["account_id"];
};

const emit = defineEmits(["submit"]);
const csvData = ref([])
const csvDownloadBtn = ref(null)
const downloadCloudAccounts = () => {
  fetch('/cloud_accounts/download', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': props.authenticity_token,
    },
    body: JSON.stringify({
      ids: download_ids.value,
      download_columns: download_columns.value,
    }),
  })
  .then(response => response.json())
  .then(data => {
    csvData.value = data
    nextTick(() => {
      document.querySelector(`#${csvDownloadBtn.value.$el.id}`).click()
    })
  })
};

onMounted(() => {
  document.querySelectorAll(".download-column").forEach((checkbox) => {
    download_columns.value.push(checkbox.value);
  });
});
</script>
