<template>
  <form @submit.prevent="formSubmit">
    <div class="card">
      <div class="card-body">
        <SubNav :sub-nav-list="props.sub_menu_lists"></SubNav>
        <Info
          @submit="modifyOrganization"
          :organization="form.organization"
          :options_for_select="props.options_for_select"
          :authenticity_token="props.authenticity_token"
        />
      </div>
      <div class="card-footer">
        <FormAction
          :can-delete="false"
          :can-submit="validate(form.organization)"
          :back-url="'/organizations'"
          :has-modified="form.isDirty"
          :submit-text="$t('helpers.create')"
          :processing="form.processing"
        />
      </div>
    </div>
  </form>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import FormAction from "@/components/form_action.vue";
import Info from "./_info.vue";
import { validate } from "./validate";

const props = defineProps({
  organization: Object,
  options_for_select: Object,
  errors: Object,
  sub_menu_lists: Array,
  authenticity_token: String,
});

const form = useForm({
  organization: props.organization,
});

const formSubmit = () => {
  form.post("/organizations");
};

const modifyOrganization = (new_value) => {
  Object.assign(form.organization, new_value);
};
</script>
