<template>
  <div class="card">
    <DataTable :resources="user_groups" :paginate="paginate">
      <template #actions>
        <Link href="/user_groups/new" class="btn btn-sm btn-primary">
          <IconSvg icon="new" class="w-4 h-4"></IconSvg>
          {{
            $t("helpers.new", { model: $t("activerecord.models.user_group") })
          }}
        </Link>
      </template>
      <template #thead>
        <DataTableHead
          v-for="thead in theads"
          :id="thead.id"
          :text="$t(thead.i18n_key)"
          :defaultOrderColumn="thead.defaultOrderColumn"
          :disableOrder="thead.disableOrder"
        >
        </DataTableHead>
      </template>
      <template #tbody-tr="{ resource }">
        <td>
          <Link
            class="text-gray-60"
            :href="`/user_groups/${resource.id}/edit`"
            >{{ resource.name }}</Link
          >
        </td>
        <td>{{ resource.description }}</td>
        <td>{{ resource.permissions }}</td>
        <td>{{ resource.users_count }}</td>
        <td>{{ resource.created_at }}</td>
      </template>
    </DataTable>
  </div>
</template>

<script setup>
defineProps({
  user_groups: {
    type: Array,
    required: true,
  },
  paginate: {
    type: Object,
    required: true,
  },
});

const theads = [
  {
    id: "name",
    i18n_key: "activerecord.attributes.user_group.name",
    defaultOrderColumn: true,
  },
  {
    id: "description",
    i18n_key: "activerecord.attributes.user_group.description",
    disableOrder: true,
  },
  {
    id: "permissions",
    i18n_key: "activerecord.attributes.user_group.permissions",
    disableOrder: true,
  },
  {
    id: "users_count",
    i18n_key: "activerecord.attributes.user_group.users_count",
    disableOrder: true,
  },
  {
    id: "created_at",
    i18n_key: "activerecord.attributes.user_group.created_at",
    disableOrder: true,
  },
];
</script>
