<template>
  <UsersIndex
    :new_user="false"
    :users="props.users"
    :paginate="props.paginate"
  >
    <template #sub_menu_lists>
      <div class="card-body">
        <SubNav :sub-nav-list="sub_menu_lists"></SubNav>
      </div>
    </template>
  </UsersIndex>
</template>
<script setup>
import UsersIndex from "@/views/users/index.vue";

const props = defineProps({
  users: {
    type: Array,
    required: true,
  },
  paginate: {
    type: Object,
    required: true,
  },
  sub_menu_lists: {
    type: Array,
    required: true,
  },
});
</script>
