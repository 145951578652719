const validPaymentTerm = (product) => {
  return product.payment_term === "other"
    ? !!product.payment_term_other
    : !!product.payment_term;
};
const validExchangeRate = (product) => {
  return product.exchange_rate === "fix_rate"
    ? Number(product.exchange_rate_fix) > 0
    : !!product.exchange_rate;
};

const validate = (product) => {
  return (
    !!product.summary_name &&
    !!product.name &&
    !!product.supplier &&
    !!product.owner_id &&
    !!product.primary_contact.email &&
    !!product.billing_contact.email &&
    !!product.primary_contact.name &&
    !!product.billing_contact.name &&
    !!product.origin_currency &&
    !!product.trade_currency &&
    validPaymentTerm(product) &&
    validExchangeRate(product)
  );
};

export { validate };
