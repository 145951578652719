import { Link, usePage } from "@inertiajs/vue3";
import FlashAlert from "@components/flash_alert.vue";
import FlashNotice from "@components/flash_notice.vue";
import PageHeader from "@components/page_header.vue";
import PageHeaderItem from "@components/page_header_item.vue";
import { DataTable, DataTableHead } from "@components/datatable";
import { format, parseISO, formatDistanceToNowStrict } from "date-fns";
import { enUS, zhTW, zhCN } from "date-fns/locale";
import CommonModal from "@components/common_modal.vue";
import ConfirmModal from "@components/confirm_modal.vue";
import AlertModal from "@components/alert_modal.vue";
import CheckIcon from "@components/check_icon.vue";
import IconSvg from "@components/icon_svg.vue";
import DropdownMenu from "@components/dropdown_menu.vue";
import Multiselect from "vue-multiselect";
import SubNav from "@components/sub_nav.vue";
import TabNav from "@components/tab_nav.vue";
import DatePicker from "@components/date_picker.vue";
// https://vuejs.org/guide/reusability/custom-directives.html

import dayjs from "dayjs";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import calender from "dayjs/plugin/calendar";
import localeData from "dayjs/plugin/localeData";
import isBetween from "dayjs/plugin/isBetween";

export default {
  // eslint-disable-next-line no-unused-vars
  install(app, options) {
    app.component("FlashAlert", FlashAlert);
    app.component("FlashNotice", FlashNotice);
    app.component("PageHeader", PageHeader);
    app.component("PageHeaderItem", PageHeaderItem);
    app.component("Link", Link);
    app.component("DataTable", DataTable);
    app.component("DataTableHead", DataTableHead);
    app.component("CommonModal", CommonModal);
    app.component("ConfirmModal", ConfirmModal);
    app.component("AlertModal", AlertModal);
    app.component("CheckIcon", CheckIcon);
    app.component("IconSvg", IconSvg);
    app.component("DropdownMenu", DropdownMenu);
    app.component("Multiselect", Multiselect);
    app.component("TabNav", TabNav);
    app.component("SubNav", SubNav);
    app.component("DatePicker", DatePicker);
    // dateFormat
    app.config.globalProperties.dateFormat = (dateTime) => {
      if (!dateTime) return undefined;

      let result = dateTime;
      if (typeof dateTime === "string") {
        result = format(parseISO(dateTime), "yyyy-MM-dd HH:mm");
      } else if (typeof dateTime === "object") {
        result = format(dateTime, "yyyy-MM-dd HH:mm");
      }

      return result;
    };

    // formatDistance
    app.config.globalProperties.formatDistance = (dateTime) => {
      if (!dateTime) return undefined;

      const locales = { en: enUS, "zh-TW": zhTW, "zh-CN": zhCN };
      const locale = locales[gon.locale] || enUS;
      let dateTimeFormat = dateTime;

      if (typeof dateTime === "string") {
        dateTimeFormat = parseISO(dateTime);
      }

      return formatDistanceToNowStrict(dateTimeFormat, {
        addSuffix: true,
        locale,
      });
    };

    // numberFormat
    app.config.globalProperties.numberFormat = (number) => {
      return Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        // specify the abbreviations to use for the suffixes
        // notation: 'compact',
        // compactDisplay: 'short'
      }).format(number);
    };

    // numberFormat by 10 thousand
    app.config.globalProperties.numberFormatByTenThousand = (number) => {
      if (number === null) return "N/A";

      return Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        // specify the abbreviations to use for the suffixes
        // notation: 'compact',
        // compactDisplay: 'short'
      }).format(number / 10000);
    };

    // nav is Active
    app.config.globalProperties.navIsActive = (url, compareType = "eq") => {
      const pageUrl = usePage().url;

      let active = false;
      if (Array.isArray(url)) {
        if (compareType === "eq") {
          active = url.includes(pageUrl);
        } else {
          active = url.some((u) => pageUrl.startsWith(u));
        }
      } else {
        active =
          compareType === "eq" ? pageUrl === url : pageUrl.startsWith(url);
      }

      return { active };
    };

    // emailValidate
    app.config.globalProperties.emailValidate = (email) => {
      return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
    };

    // cellphoneValidate
    app.config.globalProperties.cellphoneValidate = (cellphone) => {
      return /^09\d{8}$/.test(cellphone);
    };

    // dayjs
    dayjs.locale(gon.locale);
    dayjs.extend(AdvancedFormat);
    dayjs.extend(CustomParseFormat);
    dayjs.extend(calender);
    dayjs.extend(localeData);
    dayjs.extend(isBetween);

    window.dayjs = dayjs;
  },
};
