<template>
  <div id="user-content" role="tabpanel" aria-labelledby="user-tab">
    <div class="-mx-4 -mt-3 min-h-[300px]">
      <DataTable :resources="userList">
        <template #actions>
          <ConfirmModal
            :button-text="$t('user_group.user.new')"
            :modal-cancel-text="$t('helpers.back')"
            :modal-submit-text="$t('user_group.user.submit')"
            modal-size="xl"
            modalSubmitClass="btn btn-sm btn-primary"
            @submit="addUser"
            class="btn btn-sm btn-primary"
          >
            <SelectUser
              :user_ids="form.user_group.user_ids"
              :all_users="all_users"
              @submit="addTempUser"
              :key="resetSelectUser"
            />
          </ConfirmModal>
        </template>
        <template #thead>
          <DataTableHead
            v-for="thead in theads"
            :id="thead.id"
            :text="$t(thead.i18n_key)"
            :defaultOrderColumn="thead.defaultOrderColumn"
            :disableOrder="thead.disableOrder"
          >
          </DataTableHead>
          <DataTableHead
            id="actions"
            :text="$t('helpers.actions')"
            :disableOrder="true"
          >
          </DataTableHead>
        </template>
        <template #tbody-tr="{ resource }">
          <td>{{ resource.email }}</td>
          <td>{{ resource.name }}</td>
          <td>{{ resource.current_sign_in_at }}</td>
          <td>{{ resource.created_at || $t("helpers.not_added") }}</td>
          <td>
            <span
              class="underline cursor-pointer text-warning"
              @click="removeUser(resource.id)"
            >
              {{ $t("helpers.remove") }}
            </span>
          </td>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import { ref, watch, onMounted } from "vue";
import SelectUser from "./_select_user.vue";

const theads = [
  {
    id: "email",
    i18n_key: "activerecord.attributes.user.email",
    defaultOrderColumn: true,
  },
  {
    id: "name",
    i18n_key: "activerecord.attributes.user.name",
    disableOrder: true,
  },
  {
    id: "current_sign_in_at",
    i18n_key: "activerecord.attributes.user.current_sign_in_at",
    disableOrder: true,
  },
  {
    id: "created_at",
    i18n_key: "activerecord.attributes.user.created_at",
    disableOrder: true,
  },
];
const props = defineProps({
  user_group: {
    type: Object,
    required: true,
  },
  users: {
    type: Array,
    required: true,
  },
  all_users: {
    type: Array,
    required: true,
  },
});

const form = useForm({
  user_group: {
    user_ids: props.user_group.user_ids,
  },
});

const resetSelectUser = ref(0);
const userList = ref([...props.users]);

const tempUserIds = ref([]);
const addTempUser = (user_ids) => {
  tempUserIds.value = user_ids;
};

const addUser = () => {
  const newUser = props.all_users.filter((user) => {
    return tempUserIds.value.includes(user.id);
  });
  // add temp user group ids to user group list
  userList.value = [...newUser, ...userList.value];
  // remove duplicate user group
  userList.value = userList.value.filter(
    (user, index, self) => index === self.findIndex((t) => t.id === user.id)
  );
};

const removeUser = (user_id) => {
  userList.value = userList.value.filter((user) => {
    return user.id !== user_id;
  });
  tempUserIds.value = tempUserIds.value.filter((id) => {
    return id !== user_id;
  });
  resetSelectUser.value += 1;
};

const emit = defineEmits(["submit"]);
onMounted(() => {
  watch(userList, () => {
    form.user_group.user_ids = userList.value.map((user) => user.id);

    emit("submit", form.user_group);
  });
});
</script>
