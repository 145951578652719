<template>
  <!-- Modal toggle -->
  <button
    type="button"
    aria-haspopup="dialog"
    aria-expanded="false"
    :aria-controls="modalId"
    :data-hs-overlay="`#${modalId}`"
    :class="$attrs.class"
    @click.stop
  >
    <slot name="button">
      {{ buttonText || $t("helpers.delete") }}
    </slot>
  </button>

  <!-- Main modal -->
  <Teleport to="body">
    <div
      :id="modalId"
      tabindex="-1"
      role="dialog"
      data-hs-overlay-keyboard="false"
      class="hs-overlay hidden overflow-y-auto overflow-x-hidden [--overlay-backdrop:static] size-full fixed top-0 start-0 z-[80] pointer-events-none"
      :aria-labelledby="`${modalId}-title`"
    >
      <div
        class="h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] mx-auto w-full flex items-center"
        :class="{
          'max-w-4xl': modalSize === 'lg',
          'max-w-2xl': modalSize === 'md',
          'max-w-lg': modalSize === 'sm',
        }"
      >
        <!-- Modal content -->
        <div
          class="flex flex-col w-full max-h-full py-3 overflow-hidden bg-white rounded shadow pointer-events-auto"
        >
          <!-- Modal header -->
          <template v-if="hasModelHeaderSlot">
            <slot name="modelHeader"></slot>
          </template>
          <template v-else>
            <div
              v-if="props.modalTitle"
              class="flex items-center gap-5 px-4 py-3"
            >
              <h3
                :id="`${modalId}-title`"
                class="text-xl font-bold text-gray-80"
              >
                {{ props.modalTitle }}
              </h3>
              <slot name="sub-title"></slot>
            </div>
          </template>

          <!-- Modal body -->
          <div class="px-4 py-3 overflow-y-auto text-gray-80">
            <slot></slot>
          </div>

          <!-- normal modal footer -->
          <div
            class="flex items-center justify-center gap-3 px-4 py-3"
            :class="{ 'w-1/2 ml-auto': modalSize === 'xl' }"
          >
            <button
              :data-hs-overlay="`#${modalId}`"
              type="button"
              class="w-1/3 btn btn-sm"
              :class="modalSubmitClass || 'btn-primary'"
            >
              {{ modalSubmitText || $t("helpers.confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { getCurrentInstance, ref, useSlots, computed } from "vue";

const instance = getCurrentInstance();
const modalId = `default-modal-${instance.uid}`;

const props = defineProps({
  buttonText: { type: String },
  modalTitle: { type: String },
  modalSubmitClass: { type: String },
  modalSubmitText: { type: String },
  modalSize: { type: String, default: "sm" },
});

const slots = useSlots();
const hasModelHeaderSlot = computed(() => {
  return !!slots.modelHeader;
});
</script>
