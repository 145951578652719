<template>
  <li class="inline-flex items-center">
    <div class="flex items-center">
      <IconSvg
        v-if="!skipArrow"
        icon="arrow_right"
        class="w-4 h-4 mx-3 fill-black"
      ></IconSvg>
      <template v-if="currentPath">
        <h2
          v-if="hasDefaultSlot || title"
          class="text-4xl font-medium text-gray-80"
        >
          <slot>{{ title }}</slot>
        </h2>
      </template>
      <template v-else>
        <h2>
          <Link :href="href" class="text-4xl font-medium text-gray-60">
            <span
              v-if="hasDefaultSlot || title"
            >
              <slot>{{ title }}</slot>
            </span>
          </Link>
        </h2>
      </template>
    </div>
  </li>
</template>

<script setup>
import { computed, useSlots } from "vue";
import { usePage } from "@inertiajs/vue3";
const currentUser = computed(() => usePage().props.current_user);

const props = defineProps({
  href: { type: String },
  title: { type: String },
  skipArrow: { type: Boolean, default: false },
});

const slots = useSlots();

const hasDefaultSlot = computed(() => {
  return !!slots["default"];
});

const currentPath = computed(() => {
  return props.href === usePage().url;
});
</script>
