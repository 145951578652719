<template>
  <div
    id="pricing_item_history-content"
    role="tabpanel"
    aria-labelledby="pricing_item_history-tab"
    class="hidden"
  >
    <div class="flex items-center justify-end gap-2 mb-3">
      <div class="relative">
        <label for="pricing_item_history-search" class="relative block">
          <IconSvg
            icon="search"
            class="w-4 h-4 fill-gray-80 with-input"
          ></IconSvg>

          <input
            v-model="searchPricingItemHistory"
            type="text"
            id="pricing_item_history-search"
            class="input with-icon"
            :placeholder="$t('helpers.search')"
          />
        </label>
      </div>
    </div>
    <div class="gap-5 px-5 py-3 -mx-5 border-t bg-gray-5 border-gray-20">
      <div class="grid grid-cols-12 gap-5">
        <div class="col-span-2">
          <div
            class="py-0 border-t-0 border-b-0 border-l-0 border-r shadow-none card bg-gray-5"
          >
            <div class="text-center border-b-0 card-header">
              <h5 class="text-lg font-bold">成本關聯紀錄</h5>
            </div>
            <div class="card-body">
              <div class="flex flex-col items-end">
                <p class="card-title text-primary-dark">
                  {{ $t("activerecord.attributes.product.name") }}
                </p>
                <p class="mt-3 text-gray-60">
                  {{ $t("activerecord.attributes.supplier/contract.name") }}
                </p>
                <p class="mt-3 text-gray-60">
                  {{ $t("activerecord.models.supplier/pricing_item") }}
                </p>
                <p class="mt-3 text-gray-60">
                  {{
                    $t(
                      "activerecord.attributes.supplier/pricing_item.service_fee_percent"
                    )
                  }}%
                </p>
                <p class="mt-3 text-gray-60">
                  {{
                    $t(
                      "activerecord.attributes.supplier/pricing_item.common_discount_percent"
                    )
                  }}%
                </p>
                <p class="mt-3 text-gray-60">特殊折扣</p>
              </div>
            </div>
            <div class="card-footer">
              <div class="flex flex-col items-end">
                <p class="text-gray-60">合約期間</p>
                <p class="mt-3 text-gray-60">關聯期間</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-10 overflow-x-scroll">
          <div class="flex items-center gap-5">
            <div
              v-for="pricing_item_history in props.pricing_item_histories"
              :key="pricing_item_history.id"
              class="py-0 border-none card bg-gray-5"
              :class="{
                'opacity-30': !filteredPricingItemIds.includes(
                  pricing_item_history.id
                ),
              }"
            >
              <div class="card-header">
                <h5 class="text-lg font-bold">&nbsp;</h5>
              </div>
              <div class="bg-white card-body">
                <p class="card-title text-primary-dark">
                  {{ pricing_item_history.product.name }}
                </p>

                <p class="text-gray-60">
                  {{ pricing_item_history.contract.name }}
                </p>

                <p class="text-gray-60">{{ pricing_item_history.name }}</p>

                <p class="text-gray-60">
                  {{ pricing_item_history.service_fee_percent }}%
                </p>

                <p class="text-gray-60">
                  {{ pricing_item_history.common_discount_percent }}%
                </p>

                <p class="text-gray-60">
                  <template
                    v-if="pricing_item_history.special_discounts.length > 1"
                  >
                    <div
                      class="hs-tooltip [--trigger:hover] [--placement:bottom] inline-block"
                    >
                      <div class="flex items-center hs-tooltip-toggle">
                        <span class="underline text-gray-60"
                          >{{
                            $t(
                              "supplier/pricing_item.index.multiple_special_discounts"
                            )
                          }}
                          ({{
                            pricing_item_history.special_discounts.length
                          }})</span
                        >
                        <div
                          class="absolute z-10 flex flex-col invisible inline-block gap-2 px-4 py-3 transition-opacity bg-white border rounded shadow opacity-0 hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible"
                          role="tooltip"
                        >
                          <p
                            class="text-sm text-left text-gray-60"
                            v-for="special_discount in pricing_item_history.special_discounts"
                            :key="special_discount.id"
                          >
                            {{ special_discount.name }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template
                    v-if="pricing_item_history.special_discounts.length === 1"
                  >
                    <span class="text-gray-60">{{
                      pricing_item_history.special_discounts[0].name
                    }}</span>
                  </template>
                  <span class="text-gray-60" v-if="pricing_item_history.special_discounts.length === 0"> - </span>
                </p>
              </div>
              <div class="bg-white card-footer">
                <p class="flex items-center justify-between text-gray-60">
                  <span>{{ pricing_item_history.contract.start_at }}</span> ~
                  <span>{{ pricing_item_history.contract.end_at }}</span>
                </p>
                <p class="flex items-center justify-between mt-3 text-gray-60">
                  <span>{{ pricing_item_history.created_at }}</span> ~
                  <span>{{ pricing_item_history.discarded_at || '-' }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
const props = defineProps({
  pricing_item_histories: Array,
});

const searchPricingItemHistory = ref("");

const filteredPricingItemIds = computed(() => {
  return props.pricing_item_histories
    .filter((pricing_item_history) => {
      return pricing_item_history.contract.name.includes(
        searchPricingItemHistory.value
      );
    })
    .map((pricing_item_history) => pricing_item_history.id);
});
</script>
