<template>
  <ConfirmModal
    :modal-cancel-text="$t('helpers.cancel')"
    :modal-submit-text="$t('helpers.upload')"
    modal-submit-class="btn btn-sm btn-primary"
    :modal-title="$t('helpers.import')"
    @submit="importCloudAccounts"
    class="btn btn-sm btn-primary"
    :prevent-submit="!form.upload_file"
  >
    <template #button>
      <IconSvg icon="upload" class="w-4 h-4 fill-white"></IconSvg>
      {{ $t("helpers.import") }}
    </template>
    <div class="flex items-center justify-between gap-5 mx-3 mb-5">
      <label for="select_upload_file" class="required">
        {{ $t("helpers.select_file") }}
      </label>
      <span class="relative">
        <input
          type="file"
          class="input"
          id="select_upload_file"
          accept=".xls,.xlsx,.csv"
          @change="form.upload_file = $event.target.files[0]"
        />
        <span v-if="!form.upload_file" class="absolute top-0 bottom-0 left-0 right-0 bg-white cursor-pointer pointer-events-none input text-gray-20">
          {{ $t("helpers.select") }}
        </span>
        <IconSvg
          icon="upload"
          class="w-4 h-4 cursor-pointer pointer-events-none fill-black with-input input-end"
        />
      </span>
    </div>
    <p class="text-sm">
      ※僅支援xls, xlsx, csv格式的檔案
    </p>
  </ConfirmModal>
</template>

<script setup>
import { useForm, router } from "@inertiajs/vue3";

const form = useForm({
  upload_file: null,
});

const importCloudAccounts = () => {
  form.post('/cloud_accounts/import')
};
</script>
