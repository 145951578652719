
const validate = (organization) => {
  return (
    !!organization.name &&
    !!organization.primary_contact.email &&
    !!organization.billing_contact.email &&
    !!organization.primary_contact.name &&
    !!organization.billing_contact.name
  );
};

export { validate };
