const validateSupportWay = (way) => {
  return !!way.email || (!!way.group && !!way.group.sms && !!way.group.name);
};

const validate = (pricing_item) => {
  return (
    !!pricing_item.name &&
    !!pricing_item.hook_account &&
    !!pricing_item.currency &&
    !!pricing_item.exchange_rate &&
    !!pricing_item.upstream_support &&
    validateSupportWay(pricing_item.upstream_support_way) &&
    !!pricing_item.service_fee_percent &&
    !!pricing_item.common_discount_percent
  );
};

export { validate };
