const validateStructureConfig = (category, structure, structure_config) => {
  if (structure === "unified") {
    switch (category) {
      case "unit_price":
        return !!structure_config.unit_price;
      case "percentage":
        return !!structure_config.percentage;
    }
  } else {
    return !!structure_config.target_item;
  }
};

const validate = (special_discount) => {
  return (
    !!special_discount.name &&
    validateStructureConfig(
      special_discount.category,
      special_discount.structure,
      special_discount.structure_config
    )
  );
};

export { validate };
