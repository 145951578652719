const validContractName = (contract, all_contract_names) => {
  return !!contract.name && !(all_contract_names).includes(contract.name);
};

const validStartAtEndAt = (contract) => {
  return !!contract.start_at && !!contract.end_at && contract.start_at <= contract.end_at;
};

const validAdditionalInfo = (contract) => {
  return contract.category === "additional"
    ? !!contract.additional_info.main_submission_no &&
        !!contract.additional_info.main_approval_no
    : true;
};

const validate = (contract, all_contract_names) => {
  return (
    validContractName(contract, all_contract_names) &&
    validStartAtEndAt(contract) &&
    !!contract.category &&
    !!contract.approval_no &&
    !!contract.submission_no &&
    !!contract.contact.email &&
    !!contract.contact.name &&
    !!contract.bill_info_day &&
    validAdditionalInfo(contract)
  );
};

export { validate };
