<template>
  <div class="flex flex-col items-center gap-3 middle_step">
    <div class="w-full step-value-wrapper"
      :class="{
        error: !form.step.step_value || !form.step.discount,
      }"
    >
      <multiselect
        v-model="form.step.operator"
        :options="operatorOptions"
        :show-labels="false"
        :searchable="false"
        :allow-empty="false"
        :max-height="120"
        placeholder=""
        class="!min-w-[auto] multiselect-sm max-w-[max-content]"
      >
        <template #singleLabel="props">
          {{ $t(`supplier/special_discount.structure_config.${props.option}`) }}
        </template>
        <template #option="props">
          {{ $t(`supplier/special_discount.structure_config.${props.option}`) }}
        </template>
        <template #caret>
          <IconSvg
            icon="arrow_down"
            class="absolute -translate-y-1/2 top-1/2 right-2 fill-gray-80"
          ></IconSvg>
        </template>
      </multiselect>
      <input
        type="number"
        min="0"
        @wheel="$event.target.blur()"
        v-model="form.step.step_value"
        class="input input-sm text-xs !min-w-[auto] !max-w-[60px]"
        :class="{
          error: !form.step.step_value,
        }"
      />
      <button @click.stop="removeStep" class="p-0 btn btn-xs" type="button">
        <IconSvg icon="delete" class="size-4 fill-white"></IconSvg>
      </button>
    </div>
    <div class="step-node"></div>
    <div
      class="flex items-center gap-1 step_discount"
      v-if="category === 'unit_price'"
    >
      <input
        type="number"
        min="0"
        v-model="form.step.discount"
        @wheel="$event.target.blur()"
        placeholder="0.000000"
        class="input !max-w-[100px]"
        :class="{
          error: !form.step.discount,
        }"
      />
    </div>
    <div
      class="flex items-center gap-1 step_discount"
      v-if="category === 'percentage'"
    >
      -
      <input
        v-model="form.step.discount"
        type="number"
        min="0"
        @wheel="$event.target.blur()"
        class="input !max-w-[100px]"
        placeholder="0.00"
        :class="{
          error: !form.step.discount,
        }"
      />
      %
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted, computed } from "vue";
import { useForm } from "@inertiajs/vue3";
const props = defineProps({
  step: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  category: {
    type: String,
    required: true,
  },
});

const form = useForm({
  step: props.step,
});

const operatorOptions = computed(() => {
  return ['less_than', 'less_than_or_equal_to'];
});


const emit = defineEmits(["removeStep", "submit"]);

const removeStep = () => {
  emit("removeStep", props.index);
};

onMounted(() => {
  watch(form.step, () => {
    emit("submit", form.step, props.index);
  });
});
</script>
