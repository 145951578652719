<template>
  <form @submit.prevent="formSubmit">
    <div class="card">
      <div class="card-body">
        <div class="flex">
          <div
            class="flex flex-col items-start justify-between gap-3 mx-3 min-w-[550px]"
          >
            <div class="">
              <div class="flex items-center gap-1">
                <label for="email" class="required">
                  {{ $t("activerecord.attributes.user.email") }}</label
                >
                <div>
                  <input
                    type="email"
                    class="input"
                    id="email"
                    v-model="form.user.email"
                    required
                    :disabled="true"
                  />
                </div>
                <span
                  v-if="props.user.has_confirmed"
                  class="flex items-center gap-1 text-sm text-gray-40"
                >
                  <IconSvg icon="status_yes" class="fill-secondary"></IconSvg>
                </span>
                <span
                  v-else
                  class="flex items-center gap-1 text-sm text-gray-40"
                >
                  <IconSvg icon="info" class="fill-warning"></IconSvg>

                  <button
                    class="btn btn-xs btn-primary"
                    @click="handleReconfirm"
                    type="button"
                    :disabled="reconfirmCountdown > 0"
                  >
                    <span v-if="reconfirmCountdown > 0">
                      {{ `${reconfirmCountdown}s` }}
                    </span>
                    <span v-else>
                      {{ $t("user.resend_confirmation.button") }}
                    </span>
                  </button>
                </span>
              </div>
              <!-- 預留錯誤訊息空間，錯誤訊息出現時，不影響排版 -->
              <p class="w-full h-5 text-sm text-right pe-11 text-warning"></p>
            </div>
            <div>
              <div class="flex items-center gap-1">
                <label for="cellphone">{{
                  $t("activerecord.attributes.user.cellphone")
                }}</label>
                <div>
                  <input
                    type="text"
                    v-maska:unmaskedCellphone.unmasked="'####-###-###'"
                    class="input"
                    id="cellphone"
                    v-model="maskedCellphone"
                    :class="{
                      error: !cellphoneValidate(form.user.cellphone),
                    }"
                  />
                </div>
              </div>
              <p class="w-full h-5 text-sm text-center text-warning">
                <template v-if="!cellphoneValidate(form.user.cellphone)">
                  <!-- {{ $t("errors.format") }} -->
                </template>
              </p>
            </div>
          </div>
          <div class="flex flex-col items-start justify-between gap-3 mx-3">
            <div>
              <div class="flex items-center gap-1">
                <label for="name" class="required">{{
                  $t("activerecord.attributes.user.name")
                }}</label>
                <div>
                  <input
                    type="text"
                    class="input"
                    id="name"
                    v-model="form.user.name"
                    required
                    :class="{
                      error: !form.user.name,
                    }"
                  />
                </div>
              </div>
              <p class="h-5"></p>
            </div>
            <div>
              <div class="flex items-center gap-1">
                <label for="phone" class="form-label">{{
                  $t("activerecord.attributes.user.phone")
                }}</label>
                <div>
                  <input
                    type="text"
                    class="input"
                    id="phone"
                    v-model="form.user.phone"
                  />
                </div>
              </div>
              <p class="h-5"></p>
            </div>
          </div>
        </div>
        <hr class="border-gray-40" />

        <TabNav :tab-list="sub_menu_lists">
          <TabAuthentication
            @submit="modifyUser"
            :user="form.user"
            :user_id="props.user.id"
            :current_sign_in_at="props.user.current_sign_in_at"
            :current_sign_in_ip="props.user.current_sign_in_ip"
            :has_confirmed="props.user.has_confirmed"
          />

          <TabGroup
            @submit="modifyUser"
            :user="form.user"
            :user_groups="props.user_groups"
            :all_user_groups="props.all_user_groups"
          />
        </TabNav>
      </div>
      <div class="card-footer">
        <FormAction
          :can-submit="validate(form.user)"
          :back-url="'/users'"
          :delete-url="`/users/${props.user.id}`"
          :has-modified="form.isDirty"
          :delete-target="props.user.email"
          :delete-from="$t('nav.user')"
          :submit-text="$t('helpers.save')"
          :processing="form.processing"
        />
      </div>
    </div>
  </form>
</template>
<script setup>
import { useForm, router } from "@inertiajs/vue3";
import { ref, watch, onMounted } from "vue";
import { vMaska } from "maska/vue";
import FormAction from "@/components/form_action.vue";
import TabAuthentication from "./_tab_authentication.vue";
import TabGroup from "./_tab_group.vue";
import { validate } from "./validate.js";

const props = defineProps({
  user: {
    id: String,
    email: String,
    name: String,
    cellphone: String,
    phone: String,
    enable: Boolean,
    has_confirmed: Boolean,
    current_sign_in_at: String,
    current_sign_in_ip: String,
  },
  user_groups: {
    type: Array,
    required: true,
  },
  all_user_groups: {
    type: Array,
    required: true,
  },
  errors: Object,
  sub_menu_lists: Array,
});

const form = useForm({
  user: {
    email: props.user.email,
    name: props.user.name,
    cellphone: props.user.cellphone,
    phone: props.user.phone,
    enable: props.user.enable,
    user_group_ids: props.user.user_group_ids,
  },
});

const maskedCellphone = ref(form.user.cellphone || "");
const unmaskedCellphone = ref("");
defineExpose({ unmaskedCellphone: unmaskedCellphone });

const reconfirmCountdown = ref(0);
const handleReconfirm = (page) => {
  router.visit(`/users/${props.user.id}/reconfirm`, {
    method: "post",
    preserveState: true,
    onSuccess: (page) => {
      reconfirmCountdown.value = 60;
    },
  });
};

onMounted(() => {
  watch(
    () => reconfirmCountdown.value,
    (newValue, oldValue) => {
      if (newValue > 0) {
        setTimeout(() => {
          reconfirmCountdown.value--;
        }, 1000);
      }
    }
  );

  watch(unmaskedCellphone, (newValue, oldValue) => {
    form.user.cellphone = newValue;
  });
});

const formSubmit = () => {
  form.put(`/users/${props.user.id}`);
};

const modifyUser = (new_value) => {
  Object.assign(form.user, new_value);
};
</script>
