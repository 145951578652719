<template>
  <form @submit.prevent="formSubmit">
    <div class="card">
      <div class="card-body">
        <TabNav :tab-list="props.sub_menu_lists" class="py-0">
          <TabInfo
            @submit="modifyCloudAccount"
            :cloud_account="form.cloud_account"
            :options_for_select="props.options_for_select"
          />

          <TabConnectWay
            @submit="modifyCloudAccount"
            :cloud_account_connector="form.cloud_account.cloud_account_connector_attributes"
          />
          <div
            id="contract-content"
            role="tabpanel"
            aria-labelledby="contract-tab"
          ></div>
          <div
            id="comment-content"
            role="tabpanel"
            aria-labelledby="comment-tab"
          ></div>
        </TabNav>
      </div>
      <div class="card-footer">
        <FormAction
          :can-delete="false"
          :can-submit="validate(form.cloud_account)"
          :back-url="'/cloud_accounts'"
          :has-modified="form.isDirty"
          :submit-text="$t('helpers.create')"
          :processing="form.processing"
        />
      </div>
    </div>
  </form>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import FormAction from "@/components/form_action.vue";
import TabInfo from "./_tab_info.vue";
import TabConnectWay from "./_tab_connect_way.vue";
import { validate } from "./validate";

const props = defineProps({
  cloud_account: Object,
  cloud_account_connector: Object,
  options_for_select: Object,
  errors: Object,
  sub_menu_lists: Object,
});

const form = useForm({
  cloud_account: {
    ...props.cloud_account,
    cloud_account_connector_attributes: props.cloud_account_connector,
  },
});

const formSubmit = () => {
  form.post("/cloud_accounts");
};

const modifyCloudAccount = (new_value) => {
  Object.assign(form.cloud_account, new_value);
};
</script>
