<template>
  <div
    id="comment-content"
    role="tabpanel"
    aria-labelledby="comment-tab"
    class="hidden"
  >
    <div class="flex items-center justify-between">
      <ConfirmModal
        :modal-cancel-text="$t('helpers.back')"
        :modal-submit-text="$t('helpers.create')"
        :modal-title="
          $t('helpers.new', { model: $t('activerecord.models.comment') })
        "
        modalSubmitClass="btn btn-sm btn-primary"
        @submit="createComment"
        :prevent-submit="!canSubmit"
        class="my-3 btn btn-sm btn-primary"
      >
        <template #button>
          <IconSvg icon="new" class="w-4 h-4 fill-white"></IconSvg>
          {{ $t("helpers.new", { model: $t("activerecord.models.comment") }) }}
        </template>
        <dl class="grid grid-cols-4 gap-5 my-5">
          <dt class="flex items-center col-span-1">
            <label for="comment_category" class="ml-3 required">{{
              $t("activerecord.attributes.comment.category")
            }}</label>
          </dt>
          <dd class="flex items-center justify-between col-span-3">
            <div>
              <Multiselect
                v-model="form.comment.category"
                :show-labels="false"
                :searchable="false"
                :allow-empty="false"
                :options="['restricted', 'global']"
              >
                <template #singleLabel="props">
                  {{ $t(`comment.category.${props.option}`) }}
                </template>
                <template #option="props">
                  {{ $t(`comment.category.${props.option}`) }}
                </template>
                <template #caret>
                  <IconSvg
                    icon="arrow_down"
                    class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
                  ></IconSvg>
                </template>
              </Multiselect>
            </div>
          </dd>
          <dt class="flex items-center col-span-1">
            <label for="comment_content" class="ml-3 required">{{
              $t("activerecord.attributes.comment.content")
            }}</label>
          </dt>
          <dd class="flex items-center justify-between col-span-3">
            <textarea
              class="input"
              id="comment_content"
              rows="5"
              v-model="form.comment.content"
            ></textarea>
          </dd>
        </dl>
      </ConfirmModal>

      <div class="relative">
        <label for="comment-search" class="relative block">
          <IconSvg
            icon="search"
            class="w-4 h-4 fill-gray-80 with-input"
          ></IconSvg>

          <input
            v-model="search"
            type="text"
            id="comment-search"
            class="input with-icon"
            :placeholder="$t('helpers.search')"
          />
        </label>
      </div>
    </div>

    <div class="gap-5 px-5 py-3 -mx-5 border-t bg-gray-5 border-gray-20">
      <CommentItem v-for="(comment, index) in commentsFilter" :key="comment.id" :comment="comment" :reverse_layout="index%2==1" />
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { useForm } from "@inertiajs/vue3";
import CommentItem from "./_comment_item.vue";

const props = defineProps({
  cloud_account_id: { type: String, required: true },
  comments: { type: Array, required: true },
});

const form = useForm({
  comment: {
    content: "",
    category: "restricted",
  },
});

const canSubmit = computed(() => {
  return form.comment.content.trim().length > 0 && !!form.comment.category;
});

const search = ref("");

const commentsFilter = computed(() => {
  return props.comments.filter(item => item.content.includes(search.value))
})

const createComment = () => {
  form.post(`/cloud_accounts/${props.cloud_account_id}/comments`);
};
</script>
