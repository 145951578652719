<template>
  <div id="info-content" role="tabpanel" aria-labelledby="info-tab">
    <div class="space-5">
      <dl class="grid grid-cols-10 gap-5 my-5">
        <dt class="flex items-center col-span-1">
          <label for="pricing_item_name" class="ml-3 required">
            {{ $t("activerecord.attributes.supplier/pricing_item.name") }}
          </label>
        </dt>
        <dd class="col-span-4">
          <input
            v-model="form.pricing_item.name"
            type="text"
            class="flex-1 input"
            id="pricing_item_name"
          />
        </dd>
        <dt class="flex items-center col-span-1">
          <label for="pricing_item_hook_account" class="ml-3 required">
            {{
              $t("activerecord.attributes.supplier/pricing_item.hook_account")
            }}
          </label>
        </dt>
        <dd class="col-span-4">
          <multiselect
            v-model="form.pricing_item.hook_account"
            :options="hookAccountOptions"
            :show-labels="false"
            :allow-empty="false"
            :taggable="true"
            @tag="addHookAccount"
            :max-height="120"
            :placeholder="$t('helpers.select')"
            class="!min-w-[auto]"
            id="pricing_item_hook_account"
          >
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-white"
              ></IconSvg>
            </template>
          </multiselect>
        </dd>
        <dt class="flex items-center col-span-1">
          <label for="pricing_item_currency" class="ml-3 required">
            {{ $t("activerecord.attributes.supplier/pricing_item.currency") }}
          </label>
        </dt>
        <dd class="flex items-center col-span-1">
          <multiselect
            v-model="form.pricing_item.currency"
            :options="props.options_for_select.currencies"
            :show-labels="false"
            :allow-empty="false"
            :max-height="120"
            :placeholder="$t('helpers.select')"
            class="!min-w-[auto]"
            id="pricing_item_currency"
          >
            <template #singleLabel="props">
              {{ $t(`supplier/pricing_item.currency.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`supplier/pricing_item.currency.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </dd>
        <dd class="col-span-3">
          <dl class="grid grid-cols-7 gap-2">
            <dt class="flex items-center col-span-1">
              <label for="pricing_item_exchange_rate" class="ml-3 required">
                {{
                  $t(
                    "activerecord.attributes.supplier/pricing_item.exchange_rate"
                  )
                }}
              </label>
            </dt>
            <dd class="col-span-4">
              <multiselect
                v-model="form.pricing_item.exchange_rate"
                :options="props.options_for_select.exchange_rates"
                :show-labels="false"
                :searchable="false"
                :allow-empty="false"
                :max-height="120"
                class="!min-w-[auto]"
              >
                <template #singleLabel="props">
                  {{
                    $t(`supplier/pricing_item.exchange_rate.${props.option}`)
                  }}
                </template>
                <template #option="props">
                  {{
                    $t(`supplier/pricing_item.exchange_rate.${props.option}`)
                  }}
                </template>
                <template #caret>
                  <IconSvg
                    icon="arrow_down"
                    class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
                  ></IconSvg>
                </template>
              </multiselect>
            </dd>
            <dd class="col-span-2">
              <span class="flex items-center">
                <input
                  v-model="form.pricing_item.exchange_rate_percentage"
                  type="number"
                  @wheel="$event.target.blur()"
                  class="input flex-1 !min-w-[auto]"
                  id="pricing_item_exchange_rate_percentage"
                  :disabled="form.pricing_item.exchange_rate !== 'fix_rate'"
                />%
              </span>
            </dd>
          </dl>
        </dd>
        <dd class="col-span-5"></dd>
        <dt class="flex items-center col-span-1">
          <label
            for="pricing_item_upstream_technical_support"
            class="ml-3 required"
          >
            {{
              $t(
                "activerecord.attributes.supplier/pricing_item.upstream_support"
              )
            }}
          </label>
        </dt>
        <dd class="col-span-1">
          <multiselect
            v-model="form.pricing_item.upstream_support"
            :options="props.options_for_select.upstream_supports"
            :show-labels="false"
            :allow-empty="false"
            :taggable="true"
            @tag="addUpstreamSupport"
            :max-height="120"
            :placeholder="$t('helpers.select')"
            class="!min-w-[auto]"
          >
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-white"
              ></IconSvg>
            </template>
          </multiselect>
        </dd>

        <dt class="flex items-center col-span-1">
          <label for="pricing_item_email" class="ml-3 required">
            {{
              $t(
                "activerecord.attributes.supplier/pricing_item.upstream_support_way"
              )
            }}
          </label>
        </dt>
        <dd class="flex items-center col-span-3 gap-1">
          <label for="checked_pricing_item_email" class="custom-checkbox">
            <input
              type="checkbox"
              v-model="checkedUpstreamSupportEmail"
              id="checked_pricing_item_email"
            />
            {{ $t("supplier/pricing_item.upstream_support_way.email") }}
            <span class="checkmark"></span>
          </label>
          <input
            type="email"
            :disabled="!checkedUpstreamSupportEmail"
            v-model="form.pricing_item.upstream_support_way.email"
            id="pricing_item_email"
            class="input flex-1 !min-w-[auto]"
          />
        </dd>
        <dd class="flex items-center col-span-4">
          <div class="grid grid-cols-5 gap-1">
            <div class="flex items-center justify-end col-span-1">
              <label for="pricing_item_group" class="custom-checkbox">
                <input
                  type="checkbox"
                  v-model="checkedUpstreamSupportGroup"
                  id="pricing_item_group"
                />
                {{ $t("supplier/pricing_item.upstream_support_way.group") }}
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="flex items-center col-span-2">
              <multiselect
                v-model="form.pricing_item.upstream_support_way.group.sms"
                :options="props.options_for_select.upstream_support_groups"
                :show-labels="false"
                :searchable="false"
                :allow-empty="false"
                :max-height="120"
                :placeholder="$t('helpers.select')"
                :disabled="!checkedUpstreamSupportGroup"
                class="!min-w-[auto]"
              >
                <template #singleLabel="props">
                  {{
                    $t(
                      `supplier/pricing_item.upstream_support_group.${props.option}`
                    )
                  }}
                </template>
                <template #option="props">
                  {{
                    $t(
                      `supplier/pricing_item.upstream_support_group.${props.option}`
                    )
                  }}
                </template>
                <template #caret>
                  <IconSvg
                    icon="arrow_down"
                    class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
                  ></IconSvg>
                </template>
              </multiselect>
            </div>
            <div class="flex items-center col-span-2">
              <input
                v-model="form.pricing_item.upstream_support_way.group.name"
                type="text"
                id="pricing_item_group_name"
                class="input !min-w-[auto]"
                :disabled="!checkedUpstreamSupportGroup"
              />
            </div>
          </div>
        </dd>

        <dt class="flex items-center col-span-1">
          <label for="pricing_item_service_fee_percent" class="ml-3 required">
            {{
              $t(
                "activerecord.attributes.supplier/pricing_item.service_fee_percent"
              )
            }}
          </label>
        </dt>
        <dd class="flex items-center col-span-1 gap-1">
          <span
            v-t="'supplier/pricing_item.form.service_fee_percent_prefix'"
          ></span>
          <input
            v-model="form.pricing_item.service_fee_percent"
            type="number"
            @wheel="$event.target.blur()"
            class="!min-w-[auto] flex-1 input"
            id="pricing_item_service_fee_percent"
            placeholder="0.00"
            min="0"
          />
          <span>%</span>
        </dd>

        <dt class="flex items-center justify-end col-span-1">
          <label
            for="pricing_item_common_discount_percent"
            class="ml-3 required"
          >
            {{
              $t(
                "activerecord.attributes.supplier/pricing_item.common_discount_percent"
              )
            }}
          </label>
        </dt>
        <dd class="flex items-center col-span-1 gap-1">
          <span>-</span>
          <input
            v-model="form.pricing_item.common_discount_percent"
            type="number"
            min="0"
            class="!min-w-[auto] flex-1 input"
            placeholder="0.00"
            @wheel="$event.target.blur()"
            id="pricing_item_common_discount_percent"
          />
          <span>%</span>
        </dd>
        <dd class="col-span-2">
          <ConfirmModal
            :button-text="`${$t(
              'supplier/pricing_item.form.exclude_discount.button'
            )}${selectedExcludeDiscountsCount}`"
            :modal-cancel-text="$t('helpers.back')"
            modalSubmitClass="btn btn-sm btn-primary"
            class="btn btn-primary btn-sm"
          >
            <template #modelHeader>
              <div class="flex items-center justify-between px-4 py-3">
                <h3 class="text-xl font-bold text-gray-80">
                  {{
                    $t(
                      "supplier/pricing_item.form.exclude_discount.modal.title"
                    )
                  }}
                </h3>

                <div class="relative">
                  <label for="search-exclude-discount" class="relative block">
                    <IconSvg
                      icon="search"
                      class="w-4 h-4 fill-gray-80 with-input"
                    ></IconSvg>

                    <input
                      v-model="searchExcludeDiscounts"
                      type="text"
                      id="search-exclude-discount"
                      class="input with-icon"
                      :placeholder="$t('helpers.search')"
                    />
                  </label>
                </div>
              </div>
            </template>
            <div class="grid grid-cols-3 gap-2">
              <span
                v-for="(item, index) in excludeDiscountsFilter"
                :key="index"
              >
                <label :for="`exclude_item_${index}`" class="custom-checkbox">
                  <input
                    type="checkbox"
                    :id="`exclude_item_${index}`"
                    v-model="form.pricing_item.exclude_discount_ids"
                    :value="item.id"
                  />
                  {{ item.name }}
                  <span class="checkmark"></span>
                </label>
              </span>
            </div>
          </ConfirmModal>
        </dd>
      </dl>
    </div>
    <SpecialDiscountList
      :new_special_discount="props.new_special_discount"
      :special_discounts="props.special_discounts"
      :options_for_select="specialDiscountOptionsForSelect"
      @submit="modifyPricingItem"
    />
  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed } from "vue";
import { useForm } from "@inertiajs/vue3";
import SpecialDiscountList from "./special_discounts/_list.vue";

const props = defineProps({
  pricing_item: Object,
  exclude_discounts: Array,
  options_for_select: Object,
  new_special_discount: Object,
  special_discounts: {
    type: Array,
    default: [],
  },
});

const form = useForm({
  pricing_item: {
    ...props.pricing_item,
    special_discounts_attributes: props.special_discounts,
  },
});

const specialDiscountOptionsForSelect = computed(() => {
  const {
    special_discount_targets,
    special_discount_categories,
    special_discount_operators,
  } = props.options_for_select;

  return {
    special_discount_targets,
    special_discount_categories,
    special_discount_operators,
  };
});

const searchExcludeDiscounts = ref("");
const excludeDiscountsFilter = computed(() => {
  return props.exclude_discounts.filter((item) =>
    item.name.includes(searchExcludeDiscounts.value)
  );
});

const hookAccountOptions = ref(props.options_for_select.hook_accounts);
const addHookAccount = (value) => {
  hookAccountOptions.value.push(value);
  form.pricing_item.hook_account = value;
};

const upstreamSupportOptions = ref(props.options_for_select.upstream_supports);
const addUpstreamSupport = (value) => {
  upstreamSupportOptions.value.push(value);
  form.pricing_item.upstream_support = value;
};
const checkedUpstreamSupportEmail = ref(
  !!props.pricing_item.upstream_support_way.email
);
const checkedUpstreamSupportGroup = ref(
  !!props.pricing_item.upstream_support_way.group.sms
);

const selectedExcludeDiscountsCount = computed(() => {
  return form.pricing_item.exclude_discount_ids.length === 0
    ? ""
    : form.pricing_item.exclude_discount_ids.length ===
      props.exclude_discounts.length
    ? "(all)"
    : `(${form.pricing_item.exclude_discount_ids.length})`;
});

const modifyPricingItem = (new_value) => {
  Object.assign(form.pricing_item, new_value);
};

const emit = defineEmits(["submit"]);

onMounted(() => {
  watch(checkedUpstreamSupportEmail, (newVal) => {
    if (!newVal) {
      form.pricing_item.upstream_support_way.email = null;
    }
  });
  watch(checkedUpstreamSupportGroup, (newVal) => {
    if (!newVal) {
      form.pricing_item.upstream_support_way.group.sms = null;
      form.pricing_item.upstream_support_way.group.name = null;
    }
  });

  watch(form.pricing_item, () => {
    emit("submit", form.pricing_item);
  });
});
</script>
