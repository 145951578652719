<template>
  <div class="card">
    <div class="card-body">
      <SubNav :sub-nav-list="index_menu_lists"></SubNav>
      <div class="flex items-center justify-between">
        <ConfirmModal
          :modal-cancel-text="$t('helpers.back')"
          :modal-submit-text="$t('helpers.create')"
          :modal-title="$t('supplier/exclude_discount.index.title')"
          modalSubmitClass="btn btn-sm btn-primary"
          @submit="createExcludeDiscount"
          :prevent-submit="!canSubmit"
          class="my-3 btn btn-sm btn-primary"
        >
          <template #button>
            <IconSvg icon="new" class="w-4 h-4 fill-white"></IconSvg>
            {{
              $t("helpers.new", {
                model: $t("supplier/exclude_discount.index.title"),
              })
            }}
          </template>
          <dl class="grid grid-cols-4 gap-5 my-5">
            <dt class="flex items-center col-span-1">
              <label
                for="exclude_discount_name"
                class="ml-3 required"
                >{{
                  $t("activerecord.attributes.supplier/exclude_discount.name")
                }}</label
              >
            </dt>
            <dd class="flex items-center justify-between col-span-3">
              <input
                class="input"
                id="exclude_discount_name"
                v-model="form.exclude_discount.name"
              />
            </dd>
          </dl>
        </ConfirmModal>

        <div class="relative">
          <label for="exclude-discount-search" class="relative block">
            <IconSvg
              icon="search"
              class="w-4 h-4 fill-gray-80 with-input"
            ></IconSvg>

            <input
              v-model="searchExcludeDiscount"
              type="text"
              id="exclude-discount-search"
              class="input with-icon"
              :placeholder="$t('helpers.search')"
            />
          </label>
        </div>
      </div>

      <div
        class="grid grid-cols-4 gap-5 px-5 py-3 my-5 -mx-5 border-t border-b border-gray-20 bg-gray-5"
      >
        <Item
          v-for="exclude_discount in excludeDiscountsFilter"
          :exclude_discount="exclude_discount"
          :product_id="props.product_id"
          :key="exclude_discount.id"
          @submit="updateSelectedId"
        >
        </Item>
      </div>
    </div>
    <div class="flex justify-end px-5 py-3">
      <button
        type="button"
        :disabled="selectedIds.length === 0"
        @click="batchDestroy"
        class="px-10 btn btn-sm btn-warning"
      >
        {{ $t("helpers.delete") }}
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import { useForm, router } from "@inertiajs/vue3";
import Item from "./_item.vue";

const props = defineProps({
  product_id: String,
  exclude_discounts: Array,
  index_menu_lists: Array
});

const form = useForm({
  exclude_discount: {
    name: "",
  },
});

const searchExcludeDiscount = ref("");

const excludeDiscountsFilter = computed(() => {
  return props.exclude_discounts.filter(item => item.name.includes(searchExcludeDiscount.value))
})

const canSubmit = computed(() => {
  return form.exclude_discount.name.trim().length > 0;
});

const createExcludeDiscount = () => {
  form.post(`/products/${props.product_id}/exclude_discounts`);
};

const selectedIds = ref([]);
const updateSelectedId = (data) => {
  if (data.selected) {
    selectedIds.value.push(data.id);
  } else {
    selectedIds.value = selectedIds.value.filter((id) => id !== data.id);
  }
};

const batchDestroy = () => {
  router.delete(
    `/products/${props.product_id}/exclude_discounts/batch_destroy`,
    {
      data: {
        ids: selectedIds.value,
      }
    }
  );
};
</script>
