import { computed } from "vue";
const info = [
  "account_id",
  "product_main_category",
  "product_summary_name",
  "product_name",
  "supplier_pricing_item_hook_account",
  "email_name",
  "status",
  "testing_duration",
  "start_at",
  "end_at",
  "amount_currency",
  "amount",
];

const client_info = [];
const support_way = [
  "supplier_pricing_item_upstream_support",
  "supplier_pricing_item_upstream_support_way",
  "supplier_pricing_item_upstream_support_group",
];
const client_business = [];
const client_cloud_account_pricing_items = [];
const dgc_cost = [
  "hide_origin_credit",
  "supplier_contract_pricing_items_count",
  "supplier_contract_name",
  "supplier_pricing_item_name",
  "supplier_pricing_item_currency",
  "supplier_pricing_item_service_fee_percent",
  "supplier_pricing_item_common_discount_percent",
  "exclude_discounts",
  "supplier_special_discounts",
];
const supplier_cloud_account_pricing_items = [
  "supplier_cloud_account_pricing_items_contract_contact_name",
  "supplier_cloud_account_pricing_items_contract_contact_email",
  "supplier_cloud_account_pricing_items_contract_comment",
  "supplier_cloud_account_pricing_items_exchange_rate",
  "supplier_cloud_account_pricing_items_contract_name",
  "supplier_cloud_account_pricing_items_contract_start_at",
  "supplier_cloud_account_pricing_items_contract_end_at",
  "supplier_cloud_account_pricing_items_name",
  "supplier_cloud_account_pricing_items_service_fee_percent",
  "supplier_cloud_account_pricing_items_common_discount_percent",
  "supplier_cloud_account_pricing_items_special_discounts",
];

const categoryColumns = computed(() => {
  // return by order
  return {
    info,
    client_info,
    support_way,
    client_business,
    client_cloud_account_pricing_items,
    dgc_cost,
    supplier_cloud_account_pricing_items,
  };
});

export { categoryColumns };
